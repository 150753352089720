import { apiService } from '@/api/api'
import { mapFreightStatus } from '@/api/mappers/freight'
import { type AppStoreState } from '@/types'
import { Permission } from '@/types/Permission'
import {
  mdiAccountGroupOutline,
  mdiHandshake,
  mdiPackageVariantClosed,
  mdiTruckOutline,
  mdiWarehouse
} from '@mdi/js'
import { defineStore } from 'pinia'
import { useDriversStore } from './drivers'
import { useFreightStore } from './freight'
import { useVehiclesStore } from './vehicles'

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => ({
    isNavigationDrawerOpen: true,
    pages: [
      {
        title: 'Партнеры',
        path: '/partners',
        icon: mdiHandshake,
        permissions: [Permission.PARTNER_SHOW]
      },
      {
        title: 'Перевозчики',
        path: '/carriers',
        icon: mdiWarehouse,
        permissions: [Permission.CARRIER_SHOW]
      },
      {
        title: 'Водители',
        path: '/drivers',
        icon: mdiAccountGroupOutline,
        permissions: [Permission.DRIVER_SHOW, Permission.DRIVER_REQUEST_SHOW]
      },
      {
        title: 'Автомобили',
        path: '/vehicles',
        icon: mdiTruckOutline,
        permissions: [Permission.VEHICLE_SHOW, Permission.VEHICLE_REQUEST_SHOW]
      },
      {
        title: 'Грузоперевозки',
        path: '/freights',
        icon: mdiPackageVariantClosed,
        permissions: [Permission.TRANSPORTATION_SHOW]
      }
    ],
    search: null,
    isSearchInputOpen: false,
    isRouterLoading: false,
    width: 0,
    availableCitizenshipsDictionary: [],
    availableVehicleModelsDictionary: [],
    availableTrailerModelsDictionary: [],
    availableTransporationStatusesDictionary: [],
    isDriverDetailsOpen: false,
    isFreightDetailsOpen: false,
    isVehicleDetailsOpen: false,
    isCarrierDetailsOpen: false
  }),
  getters: {
    getPages(state) {
      return state.pages
    },
    isIOS() {
      return navigator.userAgent.match(/(iPad|iPhone|iPod)/i)
    },
    isAndroid() {
      return navigator.userAgent.toLowerCase().indexOf('android') > -1
    },
    smallScreen(state) {
      return state.width <= 599
    }
  },
  actions: {
    setRouterLoadingState(value: boolean) {
      this.isRouterLoading = value
    },
    setNavigationDrawerState(value: boolean) {
      this.isNavigationDrawerOpen = value
    },
    setSearch(value: string | null) {
      this.search = value
    },
    setSearchInputState(value: boolean) {
      this.isSearchInputOpen = value
    },

    setWidth(value: number) {
      this.width = value
    },
    openDriverDetails() {
      this.isDriverDetailsOpen = true
    },
    openFreightDetails() {
      this.isFreightDetailsOpen = true
    },
    openVehicleDetails() {
      this.isVehicleDetailsOpen = true
    },
    closeDriverDetails() {
      this.isDriverDetailsOpen = false
    },
    closeFreightDetails() {
      this.isFreightDetailsOpen = false
    },
    closeVehicleDetails() {
      this.isVehicleDetailsOpen = false
    },
    openCarrierDetails() {
      this.isCarrierDetailsOpen = true
    },
    closeCarrierDetails() {
      this.isCarrierDetailsOpen = false
    },

    async fetchAvailableCitizenshipsDictionary() {
      const availableCitizenships = await apiService.getAvailableCitizenShips()
      this.availableCitizenshipsDictionary = availableCitizenships
    },
    async fetchAvailableVehiclesDictionary() {
      const availableVehicleModels = await apiService.getAvailableVehicleModels()
      this.availableVehicleModelsDictionary = availableVehicleModels
    },
    async fetchAvailableTrailersDictionary() {
      const availableTrailerModels = await apiService.getAvailableTrailerModels()
      this.availableTrailerModelsDictionary = availableTrailerModels
    },
    async fetchAvailableTransporationStatusesDictionary() {
      const availableTransporationStatuses = await apiService.getAvailableTransportationStatuses()
      this.availableTransporationStatusesDictionary = availableTransporationStatuses.map(
        (item) => ({
          value: item.value,
          code: mapFreightStatus(item.code)
        })
      )
    },
    async refreshDetails() {
      const freightStore = useFreightStore()
      const vehiclesStore = useVehiclesStore()
      const driverStore = useDriversStore()
      // @ts-expect-error fix when freight store is migrated to typescript
      if (this.isFreightDetailsOpen && freightStore.detailedFreight?.id) {
        // @ts-expect-error fix when freight store is migrated to typescript
        await freightStore.fetchDetailedFreight(freightStore.detailedFreight.id)
      }

      if (this.isVehicleDetailsOpen && vehiclesStore.vehicleDetailed?.id) {
        await vehiclesStore.fetchVehicleDetailed(vehiclesStore.vehicleDetailed.id)
      }

      if (this.isDriverDetailsOpen && driverStore.driverDetailed?.id) {
        await driverStore.fetchDriverDetailed(driverStore.driverDetailed.id)
      }
    }
  }
})
