import { apiService } from '@/api/api'
import { mapDriverDTOToDriver, mapDriverDetailedDTOToDriverDetailed } from '@/api/mappers/driver'
import type { DriverStatus, DriversStoreState, PartnerBase } from '@/types'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useSnackbarStore } from './snackbar'
import { useVehiclesStore } from './vehicles'

export const useDriversStore = defineStore('drivers', {
  state: (): DriversStoreState => ({
    localeDateTime: {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    data: [],
    filterByPartner: [],
    filterByStatus: [],
    filterBySpreadingParticipation: false,
    showMap: false,
    loading: false,
    driverDetailed: undefined
  }),
  getters: {
    filterCount(state) {
      let count = 0
      count += state.filterByPartner.length > 0 ? 1 : 0
      count += state.filterByStatus.length > 0 ? 1 : 0
      count += state.filterBySpreadingParticipation ? 1 : 0
      return count
    },
    filtered(state) {
      let result = [...state.data]
      if (state.filterByPartner.length > 0) {
        result = result.filter((driver) => {
          if (driver.partner)
            return state.filterByPartner.map((partner) => partner.id).includes(driver.partner?.id)
          else return false
        })
      }
      if (state.filterByStatus.length > 0) {
        result = result.filter(({ status }) => {
          return state.filterByStatus.includes(status)
        })
      }
      return result
    },

    getDriverById: (state) => (id: number) => {
      return state.data.find((driver) => driver.id === id)
    }
  },
  actions: {
    async reset() {
      this.$reset()
    },
    async fetch(silently: boolean = false) {
      if (!silently) {
        this.loading = true
      }
      const drivers = await apiService.getDrivers(this.filterBySpreadingParticipation)
      this.data = drivers.map(mapDriverDTOToDriver)
      this.loading = false
    },
    async fetchDriverDetailed(driverId: number) {
      const driverDetailed = await apiService.getDriverDetailed(driverId)
      this.driverDetailed = mapDriverDetailedDTOToDriverDetailed(driverDetailed)
    },
    async showDriverDetails(id: number) {
      if (this.driverDetailed !== undefined) {
        const snackbarStore = useSnackbarStore()
        snackbarStore.openSnackbar({
          color: 'secondary',
          message: 'Карточка водителя уже открыта'
        })
        return
      }
      await this.fetchDriverDetailed(id)
      const appStore = useAppStore()
      appStore.openDriverDetails()
    },
    closeDriverDetails() {
      const appStore = useAppStore()
      appStore.closeDriverDetails()

      // prevent modal from jumping
      setTimeout(() => {
        this.driverDetailed = undefined
      }, 300)
    },
    async refetchDriver(id: number) {
      if (this.data.length === 0) return
      const index = this.data.findIndex((driver) => driver.id === id)
      if (index !== -1) {
        const driver = await apiService.getDriver(id)
        this.data[index] = mapDriverDTOToDriver(driver)
      }
    },
    async takeOffLine(driverId: number, vehicleId: number) {
      /**
       * TODO: do not fetch all drivers when API returns the updated driver
       */
      await apiService.takeDriverOffline(driverId)

      const appStore = useAppStore()
      const vehiclesStore = useVehiclesStore()
      await Promise.all([appStore.refreshDetails(), vehiclesStore.refetchVehicle(vehicleId)])
    },

    setFilterByPartner(value: PartnerBase[]) {
      this.filterByPartner = value
    },
    setFilterByStatus(value: DriverStatus[]) {
      this.filterByStatus = value
    },
    setShowMap(value: boolean) {
      this.showMap = value
    },
    clearFilter() {
      this.filterByPartner = []
      this.filterByStatus = []
      this.filterBySpreadingParticipation = false
      this.fetch()
    },
    setSpreadingFilter(value: boolean) {
      this.filterBySpreadingParticipation = value
      this.fetch()
    },
    async includeInSpreading(id: number) {
      await apiService.includeDriverInSpreading(id)
    },
    async excludeFromSpreading(id: number, excludeTimeInMinutes: number) {
      await apiService.excludeDriverFromSpreading(id, excludeTimeInMinutes)
    }
  }
})
